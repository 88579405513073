import React from 'react'
import styled from 'styled-components'
import Link from './Link'
import { Grid, Column } from './Grid'
import { Text } from './Text'
import Section from './Section'
import Logo from './Logo'
import StyledLink from './StyledLink'
import SplitText from './SplitText'
import { footerNavigation } from '../routes'
import { breakpoints } from '../style'
import { formatPhone } from '../lib/format'
import useSiteSettings from '../lib/useSiteSettings'
import SelectLanguageWrapper, {
  getActiveLangPath,
  isDefaultLanguage,
} from './SelectLanguage'
import { ThemeContext } from '../context/ThemeContext'

const CopyrightText = styled(Text)`
  font-size: 0.75rem;
  color: ${props => props.textColor};

  @media ${breakpoints.medium} {
    font-size: 1rem;
  }
`

const LogoIconWrapper = styled.div`
  padding-bottom: 50px;
  @media ${breakpoints.medium} {
    padding-bottom: 310px;
  }
`

const currentYear = new Date().getFullYear()

export default function Footer() {
  const siteSettings = useSiteSettings()

  const theme = React.useContext(ThemeContext)

  /* eslint no-restricted-globals:0 */
  return (
    <Section as="footer" bg={theme.background} py={[4, 7]}>
      <Grid gutter="none" mobileGutter="none">
        <Column md="8" paddingRight={0} paddingLeft={0}>
          <LogoIconWrapper>
            <Logo height={55} />
          </LogoIconWrapper>
        </Column>
        <Column md="4" paddingRight={0} paddingLeft={0}>
          <ul
            style={{
              display: 'flex',
            }}
          >
            <SelectLanguageWrapper
              textColor={theme.color}
              styleVariant={theme.theme}
            />
          </ul>
        </Column>
        {siteSettings.offices.map(office => (
          <Column
            sm={12}
            md={3}
            key={office.city}
            paddingRight={0}
            paddingLeft={0}
          >
            <Text as="address" textColor={theme.color}>
              {office.address} <br />
              {office.zipcode} {office.city} <br />
              <Link
                href={`tel:${formatPhone(office.phone)}`}
                textColor={theme.color}
                styleVariant={theme.theme}
              >
                {office.phone}
              </Link>
            </Text>
          </Column>
        ))}
        <Column sm={12} md={3} paddingRight={0} paddingLeft={0}>
          <Text>
            <StyledLink
              href={siteSettings.social.instagram}
              target="_blank"
              rel="noopener noreferrer"
              textColor={theme.color}
            >
              <SplitText copy="Instagram" role="heading" type="chars" />
              <SplitText copy="Instagram" role="heading" type="chars" />
            </StyledLink>

            <StyledLink
              href={siteSettings.social.facebook}
              target="_blank"
              rel="noopener noreferrer"
              textColor={theme.color}
            >
              <SplitText copy="Facebook" role="heading" type="chars" />
              <SplitText copy="Facebook" role="heading" type="chars" />
            </StyledLink>

            <StyledLink
              href={siteSettings.social.linkedin}
              target="_blank"
              rel="noopener noreferrer"
              textColor={theme.color}
            >
              <SplitText copy="LinkedIn" role="heading" type="chars" />
              <SplitText copy="LinkedIn" role="heading" type="chars" />
            </StyledLink>

            <StyledLink
              href={siteSettings.social.github}
              target="_blank"
              rel="noopener noreferrer"
              textColor={theme.color}
            >
              <SplitText copy="GitHub" role="heading" type="chars" />
              <SplitText copy="GitHub" role="heading" type="chars" />
            </StyledLink>
          </Text>
        </Column>
        <Column md="3" paddingRight={0} paddingLeft={0}>
          <Text as="ul">
            {footerNavigation.map(route => (
              <li key={route.link}>
                <StyledLink
                  to={`${getActiveLangPath()}/${route.link}`}
                  textColor={theme.color}
                >
                  <SplitText
                    copy={isDefaultLanguage() ? route.title : route.sv.title}
                    role="heading"
                    type="chars"
                  />
                  <SplitText
                    copy={isDefaultLanguage() ? route.title : route.sv.title}
                    role="heading"
                    type="chars"
                  />
                </StyledLink>
              </li>
            ))}
          </Text>
        </Column>
      </Grid>
      <Grid
        justifyContent="space-between"
        alignItems="flex-end"
        mt="4"
        gutter="none"
        mobileGutter="none"
      >
        <Column width="auto" paddingRight={0} paddingLeft={0}>
          <CopyrightText as="small" textColor={theme.color}>
            © {currentYear} <br />
            {siteSettings.name} <br />
            {isDefaultLanguage()
              ? 'Making great things in Stockholm'
              : 'Att göra fantastiska saker i Stockholm'}
          </CopyrightText>
        </Column>
      </Grid>
    </Section>
  )
}
