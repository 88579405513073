import React from 'react'
import styled from 'styled-components'
import { ChevronDown } from 'lucide-react'
import Section from './Section'
import { IconButton } from './Button'
import { Grid, Column } from './Grid'

export const StyledHero = styled(Section)(
  props =>
    props.scrollButtonElement && {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  props =>
    !props.keepContentMargin && {
      [`${Column} > *:last-child`]: {
        marginBottom: 0,
      },
    },
  props =>
    props.alignment === 'center' && {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },

  props =>
    props.fullWidth && {
      maxWidth: '100%',
      width: 'max(700px, 60vw)',
      margin: '0 auto',
    },

  props =>
    props.position === 'absolute' && {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      position: 'absolute',
    },
)

export default function Hero({
  children,
  pt = 8,
  pb = [3, 7],
  // md = 12,
  scrollButtonElement,
  keepContentMargin,
  alignment,
  fullWidth,
  position,
  columnGap,
  rowGap,
  textAlign,
  alignItems,
  justifyContent,
  md,
}) {
  return (
    <StyledHero
      pt={pt}
      pb={pb}
      keepContentMargin={keepContentMargin}
      alignment={alignment}
      scrollButtonElement={!!scrollButtonElement}
      fullWidth={fullWidth}
      position={position}
    >
      <Grid
        mt="auto"
        textAlign={textAlign}
        columnGap={columnGap}
        rowGap={rowGap}
        alignItems={alignItems}
        justifyContent={justifyContent}
      >
        <Column md={md} textAlign={textAlign}>
          {children}
        </Column>
      </Grid>
      {scrollButtonElement && (
        <Grid justifyContent="center" mt={[4, 5, 5]}>
          <Column width="auto">
            <IconButton
              aria-label="Skrolla till nästa section"
              onClick={event => {
                event.preventDefault()
                const element =
                  typeof scrollButtonElement === 'string'
                    ? document.querySelector(scrollButtonElement)
                    : scrollButtonElement

                element.scrollIntoView({
                  block: 'start',
                  behavior: 'smooth',
                })
              }}
            >
              <ChevronDown color="white" />
            </IconButton>
          </Column>
        </Grid>
      )}
    </StyledHero>
  )
}
