import React from 'react'
import styled from 'styled-components'
import Meta from './Meta'
import Footer from './Footer'
import { ThemeContext } from '../context/ThemeContext'
import { breakpoints, fluidRange, vw } from '../style'

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: ${props => props.background};
  transition: background 0.45s ease;

  padding-right: ${fluidRange({ min: 8, max: 12 })};
  padding-left: ${fluidRange({ min: 8, max: 12 })};

  @media ${breakpoints.small} {
    padding-right: ${vw(40)};
    padding-left: ${vw(40)};
  }
`

const Main = styled.main`
  flex-grow: 1;
`

export default function Layout({ meta, hideFooter, children }) {
  return (
    <ThemeContext.Consumer>
      {theme => (
        <PageWrapper background={theme.background}>
          {meta && <Meta {...meta} />}
          <Main id="main-content">{children}</Main>
          {!hideFooter && <Footer />}
        </PageWrapper>
      )}
    </ThemeContext.Consumer>
  )
}
